<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <h1 class="text-xl text-left font-extrabold pl-4">Organisation</h1>
      <div class="w-7/12 mt-1 ml-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div>
      <div class="mx-3 mt-6">
        <Card class="mt-6 p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button class="text-white" @click="handleClick"
              :background="appearance.buttonColor || defaultBTNColor"
            >
              New Job Level
            </Button>
          </div>
          <template v-if="!showError" v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @print="print"
              @reload="reload()"
              @sortType="handleSort($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div v-if="!showError" class="p-3">
        <sTable
          :headers="levelHeader"
          :items="levelArray"
          :loading="loadingData"
          aria-label="leveltable"
          :pagination-list="metaData"
          @page="handlePage($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          id="printMe"
          style="width: 100%"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <span v-if="item.category">{{
              item.data.category ? item.data.category.name : "Nil"
            }}</span>
            <span v-if="item.name">{{ item.data.name }}</span>
            <span v-if="item.tags" class="flex flex-wrap">
              <div
                v-for="(tag, index) in item.data.tags"
                :class="`tag_${tag.name}`"
                class="p-1 mr-2 tag"
                :key="index"
              >
                {{ tag.name }}
              </div>
            </span>

            <span v-else-if="item.id">
              <Menu left top="-150" margin="40" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div style="width: 116px; height: 81px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleEdit(item.data.id)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Edit</p>
                  </div>
                  <div
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="confirmDelete(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Delete</p>
                  </div>
                </div>
              </Menu>
            </span>
          </template>
        </sTable>
      </div>
      <div v-else>
        <error-page />
      </div>
    </div>
    <RightSideBar
      v-if="openModal"
      @submit="editMode ? updateLevel() : createLevel()"
      @close="openModal = false"
      submit="Save"
      :loading="loading"
      :button-class="`bg-dynamicBackBtn text-white`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          {{ editMode ? "Edit Job Level" : "Add New Job Level" }}
        </p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple">
          Which of these five best describe the Job Level?
        </p>
        <radio-button
          :options="categoryArray"
          col-span="col-span-12"
          class="m-4 text-darkPurple"
          @change="handleRadioChange"
          row-gap="gap-y-5"
          v-model="level.categoryId"
        />
      </template>
      <div>
        <p class="text-darkPurple">Job Level Details</p>
        <div
          class="grid grid-cols-12 gap-7 text-darkPurple"
          style="margin-top: 1.125rem"
        >
          <div class="col-span-12">
            <div class="text-sm mb-3">Description</div>
            <!-- <c-select
              placeholder="--Select--"
              :options="tagArray"
              @input="checkTag"
              variant="w-full"
            /> -->
            <div class="flex flex-wrap">
              <div
                class="flex justify-between p-2 mt-3 mr-2"
                v-for="(tag, index) in tagArray"
                :key="index"
                style="background: #f7f7ff; border-radius: 5px"
              >
                <p class="mr-2">
                  {{ tag.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-12">
            <div class="text-sm mb-3">Level ID</div>
            <c-text
              placeholder="--Enter Level ID--"
              variant="w-full h-10"
              style="height: 40px !important"
              v-model="level.name"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Level?
          </p>
          <div class="flex">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white" @click="deleteLevel"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import Modal from "@/components/Modal";

export default {
  name: "Designation",
  components: {
    Button,
    Card,
    Breadcrumb,
    Modal,
    CardFooter,
    ErrorPage,
    CText,
    Menu,
    STable,
    RightSideBar,
    RadioButton,
  },
  data() {
    return {
      levelArray: [],
      openModal: false,
      dialogDel: false,
      loadingData: true,
      editMode: false,
      showError: false,
      loading: false,
      tagArray: [],
      metaData: {},
      disableBtn: false,
      hierarchy: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      sortType: null,
      chipTag: [],
      level: {
        name: "",
        categoryId: null,
      },
      levelId: "",
      breadcrumbs: [
        {
          disabled: false,
          text: "Organisation",
          id: "",
        },
        {
          disabled: false,
          text: "Job Levels",
          id: "Job Levels",
        },
      ],
      categoryArray: [],
      levelHeader: [
        { title: "CATEGORY", value: "category" },
        { title: "job level id", value: "name", width: "20" },
        { title: "DESCRIPTION", value: "tags", width: "50" },
        { title: "", value: "id", image: true },
      ],
    };
  },
  methods: {
    handleRadioChange(value) {
      const category = this.categoryArray.find((item) => item.id === value);

      if (category) {
        this.tagArray = category.tags;
      }
    },
    async confirmDelete(id) {
      try {
        await this.$handlePrivilege("jobLevels", "deleteJobLevel");
        this.levelId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deleteLevel() {
      try {
        this.dialogDel = false;
        this.loadingData = true;
        await this.$_deleteLevel([this.levelId]);
        this.getLevels("");
        this.$toasted.success("Level deleted successfully", { duration: 3000 });
      } catch (error) {
        this.loadingData = false;
        this.$toasted.error("Level not deleted", { duration: 3000 });
      }
    },
    async handleClick() {
      try {
        await this.$handlePrivilege("jobLevels", "addNewJobLevel");
        this.chipTag = [];
        this.level = { name: "", categoryId: null };
        this.editMode = false;
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleEdit(id) {
      try {
        await this.$handlePrivilege("jobLevels", "editJobLevel");
        this.loading = true;
        this.openModal = true;
        this.editMode = true;
        this.$_getOneLevel({ levelId: id }).then((response) => {
          this.level.categoryId = response.data.level.category.id;
          this.level.name = response.data.level.name;
          this.tagArray = response.data.level.tags;
          this.loading = false;
          this.levelId = id;
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async updateLevel() {
      this.loadingData = true;
      const levelObject = {
        levelTags: [],
        newTags: [],
      };
      this.disableBtn = true;

      levelObject.levelId = this.levelId;
      levelObject.name = this.level.name;
      levelObject.categoryId = this.level.categoryId;
      this.tagArray.forEach((element) => {
        if (element.id) {
          levelObject.levelTags.push({ tagId: element.id });
        } else {
          levelObject.newTags.push(element);
        }
      });
      try {
        await this.$_updateLevel(levelObject);
        this.getLevels("");
        this.openModal = false;
        this.$toasted.success("Level edited successfully", { duration: 3000 });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
      } catch (err) {
        this.$toasted.error("Level not edited successfully", {
          duration: 3000,
        });
        this.disableBtn = false;
        throw new Error(err);
      }
    },
    async createLevel() {
      const levelObject = {
        level: {
          levelTags: [],
        },
        tags: [],
      };
      this.disableBtn = true;

      levelObject.level.orgId = this.$AuthUser.orgId;
      levelObject.level.name = this.level.name;
      levelObject.level.categoryId = this.level.categoryId;
      this.tagArray.forEach((element) => {
        if (element.id) {
          levelObject.level.levelTags.push({ tagId: element.id });
        } else {
          levelObject.tags.push(element);
        }
      });

      try {
        await this.$_createLevel(levelObject);
        this.loadingData = true;
        this.chipTag = [];
        this.level.categoryId = null;
        this.getLevels("");
        this.openModal = false;
        this.$toasted.success("Level created successfully", { duration: 3000 });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
      } catch (error) {
        this.chipTag = [];
        this.level.categoryId = null;
        this.openModal = false;
        this.$toasted.error("Level not created successfully", {
          duration: 3000,
        });
        this.disableBtn = false;
      }
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getLevels(`${sort}${search}${pageNumber}${itemPage}`);
    },
    checkTag(value) {
      const tag = this.tagArray.find((element) => element.id === value);
      if (tag) {
        const isPresent = this.chipTag.find((element) => element.id === tag.id);
        if (isPresent) {
          return;
        }
        this.chipTag.push({ name: tag.name, id: tag.id });
      } else {
        this.chipTag.push({ name: value, orgId: this.$AuthUser.orgId });
      }
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      this.loadingData = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSort(sortType) {
      this.loadingData = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    reload() {
      this.getLevels("");
    },
    async getLevels(payload) {
      this.loadingData = true;
      try {
        const response = await this.$_getLevels(payload);
        this.levelArray = response.data.levels;
        this.metaData = response.data.meta;
        this.loadingData = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCategory() {
      try {
        const response = await this.$_getCategory();
        this.categoryArray = response.data.categories;
        this.categoryArray = this.categoryArray.map((element) => ({
          name: element.name,
          value: element.id,
          radioName: "job",
          ...element,
        }));
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  async mounted() {
    try {
      await this.$handlePrivilege("jobLevels", "viewRegister");
      this.getLevels("");
      this.getCategory();
      this.showError = false
    } catch (error) {
      this.showError = true
      this.loadingData = false
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-radius {
  border-radius: 7px;
}
.tag {
  border-radius: 5px;
  background: rgba(135, 142, 153, 0.15);
  color: #333333;
  &_Authority {
    background: rgba(47, 128, 237, 0.15);
    color: #2176ff;
  }
  &_Leadership {
    background: rgba(241, 90, 41, 0.15);
    color: #f15a29;
  }
  &_Strategy {
    background: rgba(19, 181, 106, 0.15);
    color: #27ae60;
  }
  &_Oversight {
    background: rgba(242, 201, 76, 0.15);
    color: #e99323;
  }
  &_GoalSetting {
    background: rgba(135, 142, 153, 0.15);
    color: #333333;
  }
}
</style>
